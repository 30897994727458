<template>
  <v-app id="inspire">
    <v-main class="lighten-3">
      <v-container fluid>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
        >
          <v-card>
            <form @submit.prevent="handleSubmit">
              <v-card-title>
                <span class="text-h5">Авторизация</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          label="Email*"
                          required
                          v-model="username"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                          label="Пароль*"
                          type="password"
                          required
                          v-model="password"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
                <small>*обязательные поля</small>
              </v-card-text>
              <v-card-actions>
                <v-btn
                    color="blue darken-1"
                    text
                    type="submit"
                >
                  Войти
                </v-btn>
              </v-card-actions>
            </form>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>


export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      submitted: false,
      dialog: true,
    }
  },
  computed: {
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    }
  },

  methods: {
    handleSubmit() {
      this.submitted = true;
      const {username, password} = this;
      const {dispatch} = this.$store;
      if (username && password) {
        dispatch('authentication/login', {username, password});
      }
    }
  },

  watch: {
    $route: 'fetchData',
  },

  created() {
    // reset login status
    this.$store.dispatch('authentication/logout');
  },
}
</script>
